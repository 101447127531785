import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import fetch from 'cross-fetch';
import { Cookies } from 'react-cookie';

type AbstractGraphQLClient =
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mutate: () => Promise<any>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      query: () => Promise<any>;
    }
  | ApolloClient<NormalizedCacheObject>;

export const getGraphQLClient = (): AbstractGraphQLClient => {
  const cookies = new Cookies();
  const csrfToken: string = decodeURIComponent(cookies.get('XSRF-TOKEN') as string);
  const httpLink = createHttpLink({
    uri: String(process.env.REACT_PARTNERS_API_BASE_URL) + '/graphql',
    headers: {
      'X-XSRF-TOKEN': csrfToken,
      Accept: 'application/json',
    },
    credentials: 'include',
    fetch,
  });

  return new ApolloClient<NormalizedCacheObject>({
    link: httpLink,
    cache: new InMemoryCache(),
    credentials: 'include',
  });
};
