import { Payout } from './Payout';
import { Property } from './Property';
import { Schedule } from './Schedule';
import { Technician } from './Technician';

export const KEY_NEW_PROPERTY = 'NEW_PROPERTY';
export const KEY_AWAITING_SERVICE_WINDOW = 'AWAITING_SERVICE_WINDOW';
export const KEY_AWAITING_SCHEDULE = 'AWAITING_SCHEDULE';
export const KEY_SCHEDULED = 'SCHEDULED';
export const KEY_AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION';
export const KEY_COMPLETE = 'COMPLETE';
export const KEY_CANCELLED = 'CANCELLED';
export const KEY_CANCELLED_RECENTLY_BY_CUSTOMER = 'CANCELLED_RECENTLY_BY_CUSTOMER';
export const KEY_CANCELLED_CONFIRMED_BY_PARTNER = 'CANCELLED_CONFIRMED_BY_PARTNER';

export const VALUE_NEW_PROPERTY = 'New Property';
export const VALUE_AWAITING_SERVICE_WINDOW = 'Awaiting Service Window';
export const VALUE_AWAITING_SCHEDULE = 'Awaiting Schedule';
export const VALUE_SCHEDULED = 'Scheduled';
export const VALUE_AWAITING_CONFIRMATION = 'Awaiting Confirmation';
export const VALUE_COMPLETE = 'Complete';
export const VALUE_CANCELLED_RECENTLY_BY_CUSTOMER = 'Recent Cancellation';
export const VALUE_CANCELLED_CONFIRMED_BY_PARTNER = 'Cancelled';

export type ScheduleEvent = {
  id: string;
  schedule: Schedule;
  property?: Property;
  servicePayout: Payout | null;
  windowStart: string;
  windowEnd: string;
  startedAt: string | null;
  completedAt: string | null;
  status: string;
  productsUsed: string | null;
  notes: string | null;
  technician: Technician | null;
};

export type ScheduleEventCSVExport = ScheduleEvent & {
  'servicePayout.payout'?: number;
  'schedule.property.address1'?: string;
  'schedule.property.city'?: string;
  'schedule.property.state'?: string;
  'schedule.property.postalCode'?: string;
  'schedule.property.yardSizeInSqft'?: number;
};

export const EXTERNAL_STATUS_MAP: Record<string, string> = {
  [KEY_NEW_PROPERTY]: VALUE_NEW_PROPERTY,
  [KEY_AWAITING_SERVICE_WINDOW]: VALUE_AWAITING_SERVICE_WINDOW,
  [KEY_AWAITING_SCHEDULE]: VALUE_AWAITING_SCHEDULE,
  [KEY_SCHEDULED]: VALUE_SCHEDULED,
  [KEY_AWAITING_CONFIRMATION]: VALUE_AWAITING_CONFIRMATION,
  [KEY_COMPLETE]: VALUE_COMPLETE,
  [KEY_CANCELLED_RECENTLY_BY_CUSTOMER]: VALUE_CANCELLED_RECENTLY_BY_CUSTOMER,
  [KEY_CANCELLED_CONFIRMED_BY_PARTNER]: VALUE_CANCELLED_CONFIRMED_BY_PARTNER,
} as const;

export const EXTERNAL_STATUS_COLOR: Record<
  string,
  'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
> = {
  [KEY_NEW_PROPERTY]: 'success',
  [KEY_AWAITING_SERVICE_WINDOW]: 'warning',
  [KEY_AWAITING_SCHEDULE]: 'error',
  [KEY_SCHEDULED]: 'info',
  [KEY_AWAITING_CONFIRMATION]: 'primary',
  [KEY_COMPLETE]: 'default',
  [KEY_CANCELLED]: 'error',
  [KEY_CANCELLED_RECENTLY_BY_CUSTOMER]: 'secondary',
  [KEY_CANCELLED_CONFIRMED_BY_PARTNER]: 'error',
} as const;

export const FILTER_COLUMN_OPERATOR: Record<string, 'EQ' | 'LIKE'> = {
  id: 'EQ',
  status: 'EQ',
  customerName: 'LIKE',
  address: 'LIKE',
};

export type GetScheduleEventsResponse = {
  data: {
    GetScheduleEvents: ScheduleEventData;
  };
  errors: GraphqlError[];
};

export type ScheduleEventData = {
  data: ScheduleEvent[];
  paginatorInfo: {
    currentPage: number;
    total: number;
    lastPage: number;
  };
};

export type ScheduleEventsFilterInput = {
  column: string;
  value: string;
  operator?: string;
};

export type ScheduleEventsSortInput = {
  column: string;
  order?: string;
};

export type GraphqlError = {
  message: string;
  debugMessage?: string;
};
