import { gql } from '@apollo/client';

export const ImportQuery = gql`
  query ($id: HashID!) {
    import(id: $id) {
      id
      status
      fileName
      fileSize
      totalLines
      processedLines
      erroredLines
    }
  }
`;
