import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Logout from '@mui/icons-material/Logout';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';

import { SanctumReturn, User } from '../Types/Auth';

export default function UserMenu() {
  const { signOut, authenticated, user, checkAuthentication }: SanctumReturn<User> = useSanctum();
  const navigate = useNavigate();

  const LogoutFunc = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    signOut().finally(() => navigate('/'));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkAuthentication().then(null).catch(null);
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (open) {
      handleClose();
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!authenticated ? (
        <></>
      ) : (
        <Box sx={{ flexGrow: 0 }}>
          <Button
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={'true'}
            onClick={handleClick}
            className="menu-trigger"
            sx={[
              {
                borderRadius: 90,
                color: 'text.primary',
                minWidth: 120,
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
              },
              {
                '&:hover': {
                  boxShadow: '0 1px 8px rgba(0, 0, 0, 0.3)',
                  backgroundColor: 'unset',
                },
              },
            ]}
          >
            <span>{user.name}</span>
            <PersonIcon />
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            data-testid="user-menu-backdrop"
          >
            <MenuItem disableRipple sx={{ cursor: 'default' }}>
              <ListItemIcon>
                <AccountBoxIcon fontSize="small" />
              </ListItemIcon>
              {user.name}
            </MenuItem>
            <MenuItem disableRipple sx={{ cursor: 'default' }}>
              <ListItemIcon>
                <MailIcon fontSize="small" />
              </ListItemIcon>
              {user.email}
            </MenuItem>
            <Divider />
            <MenuItem onClick={LogoutFunc}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
}
