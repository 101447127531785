import { gql } from '@apollo/client';

export const GetExportQuery = gql`
  query ($id: HashID!) {
    export(id: $id) {
      id
      tenant {
        id
      }
      exportType
      status
      filters
      fileName
      fileSize
      fileHash
      publicUrl
      totalRows
    }
  }
`;
