import '@fontsource/roboto/400.css';

import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { version } from '../package.json';
import App from './App';
import { store } from './app/store';

export const root = createRoot(document.getElementById('app')!);

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: String(version),
  environment: String(process.env.APP_ENV),
  enabled: Boolean(process.env.SENTRY_ENABLED),
});

root.render(
  <StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
);
