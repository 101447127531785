import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';

import TopBar from '../components/TopBar';
import { CLOSE_ALERT_AUTOMATICALLY_IN_MS } from '../constants';
import { getGraphQLClient } from '../GraphQL';
import { SanctumReturn, User } from '../Types/Auth';
import { ImportRow, ImportRowResult } from '../Types/ImportRow';
import { MTableToolbarProps } from '../Types/MaterialTable';
import { ImportRowQuery } from './ImportRowQuery';

export interface DashboardProps {
  graphQLClient?: ApolloClient<NormalizedCacheObject>;
}

const remoteData = (importId: string | undefined) =>
  getGraphQLClient().query({
    query: ImportRowQuery,
    variables: { id: importId },
  });

export default function ImportErrors() {
  const { authenticated, checkAuthentication }: SanctumReturn<User> = useSanctum();
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderAlert, setShouldRenderAlert] = useState(false);
  const [data, setData] = useState<Array<ImportRow> | undefined>();
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const params = useParams();
  const dashboardUrl = '/active-customers';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkAuthentication().finally(() => {
      if (false === authenticated) {
        navigate('/login');
        return;
      } else if (null === authenticated) {
        return;
      }

      if (isLoading) {
        remoteData(params.id)
          .then((res: ImportRowResult) => {
            setShouldRenderAlert(true);
            setData(res.data.import.importRows);
            setTimeout(() => setShouldRenderAlert(false), CLOSE_ALERT_AUTOMATICALLY_IN_MS);
          })
          .catch(() => {
            setError('The import could not be found.');
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  });

  const renderAlert = () => {
    if (!shouldRenderAlert) {
      return null;
    }

    return (
      <Alert severity="error">
        <AlertTitle>Data Import Failed</AlertTitle>
        There were errors found. Your file was not uploaded and the database was not updated. Please correct the issues
        below and re-import your file.
      </Alert>
    );
  };

  const renderImportErrors = () => {
    if (isLoading && !data) {
      return (
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress title="Loading Import Data" />
        </Box>
      );
    }

    if (error) {
      return (
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <Typography component="h1" variant="h6">
            {error}
          </Typography>
        </Container>
      );
    }

    if (data) {
      const columns = [
        { title: 'Row#', field: 'rowNumber', width: '5rem' },
        { title: 'Schedule Event ID', field: 'scheduleEventId' },
        { title: 'Field', field: 'field' },
        { title: 'Value', field: 'value' },
        { title: 'Error Description', field: 'error', width: '50%' },
      ];

      const errorList: Array<Record<string, string>> = [];

      data
        .filter((row) => row.errors)
        .forEach((row) =>
          Object.keys(row.errors).forEach((col) => {
            row.errors[col].forEach((err) =>
              errorList.push({
                id: row.id,
                rowNumber: row.lineNumber.toString(),
                field: col,
                value: row.data[col],
                scheduleEventId: row.data['Schedule Event ID'],
                error: err,
              }),
            );
          }),
        );

      return (
        <MaterialTable
          title="Import Errors"
          columns={columns}
          data={errorList}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props: MTableToolbarProps<object>) => (
              <Grid container justifyContent="flex-end" columnSpacing={2}>
                <Grid item>
                  <Button component={Link} to={dashboardUrl} variant="outlined" color="primary">
                    Return to the Dashboard
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={() => ExportCsv(props.columns, props.originalData, 'errors')}>
                    Download
                  </Button>
                </Grid>
              </Grid>
            ),
          }}
          options={{
            tableLayout: 'fixed',
            paging: false,
            search: false,
            showTitle: false,
            toolbar: true,
            // https://github.com/material-table-core/core/issues/124
            rowStyle: { fontFamily: 'Roboto', fontSize: '0.875rem', overflowWrap: 'break-word' },
            headerStyle: { fontWeight: 'bold' },
          }}
        />
      );
    }
  };

  return (
    <Container maxWidth={false}>
      <TopBar />
      <Container maxWidth="xl">
        <Container style={{ maxWidth: '98%' }}>{renderAlert()}</Container>
        <Box sx={{ padding: '1rem' }}>
          <Typography component="h1" variant="h6">
            Import Errors
          </Typography>
          {renderImportErrors()}
        </Box>
      </Container>
    </Container>
  );
}
