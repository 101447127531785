import { gql } from '@apollo/client';

export const TableQuery = gql`
  query ($filterBy: [ScheduleEventsFilterInput!], $sortBy: [ScheduleEventsSortInput!], $page: Int, $first: Int) {
    GetScheduleEvents(filterBy: $filterBy, sortBy: $sortBy, page: $page, first: $first) {
      data {
        id
        schedule {
          id
          property {
            id
            customerName
            address1
            city
            state
            postalCode
            yardSizeInSqft
          }
          service {
            type
          }
        }
        servicePayout {
          payout
        }
        technician {
          name
        }
        notes
        productsUsed
        windowStart
        windowEnd
        startedAt
        completedAt
        status
        createdAt
        updatedAt
      }
      paginatorInfo {
        count
        currentPage
        lastPage
        perPage
        total
      }
    }
  }
`;
