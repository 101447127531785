{
  "name": "partners.lawnstarter.com",
  "version": "1.0.0-alpha",
  "source": "src/index.html",
  "license": "UNLICENSED",
  "private": true,
  "scripts": {
    "build": "parcel build",
    "clean": "rm -rf .parcel-cache coverage dist",
    "lint": "eslint . --ext .js,.ts,.tsx --ignore-path .gitignore",
    "lint:fix": "yarn lint --fix",
    "prepare": "husky",
    "start": "yarn clean && parcel --open",
    "test": "jest",
    "test:ci": "CI=true jest --coverage --colors",
    "test:watch": "jest --watch",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject ./dist && sentry-cli sourcemaps upload ./dist",
    "cypress:lint": "yarn gherkin-lint cypress/e2e",
    "cypress:open": "cypress open"
  },
  "dependencies": {
    "@apollo/client": "^3.9.5",
    "@emotion/react": "^11.11.4",
    "@emotion/styled": "^11.11.0",
    "@fontsource/roboto": "^5.0.12",
    "@material-table/core": "6.1.15",
    "@material-table/exporters": "1.0.12",
    "@mui/material": "^5.15.11",
    "@reduxjs/toolkit": "^2.2.1",
    "@sentry/react": "^7.105.0",
    "cross-fetch": "^4.0.0",
    "filefy": "^0.1.10",
    "graphql": "^16.8.1",
    "react": "^18.2.0",
    "react-cookie": "^7.1.0",
    "react-dom": "^18.2.0",
    "react-dropzone": "^14.2.3",
    "react-redux": "^9.1.0",
    "react-router-dom": "^6.22.2",
    "react-sanctum": "^3.0.0"
  },
  "devDependencies": {
    "@babel/core": "^7.24.0",
    "@badeball/cypress-cucumber-preprocessor": "20.0.2",
    "@bahmutov/cypress-esbuild-preprocessor": "2.2.0",
    "@commitlint/cli": "^19.0.3",
    "@parcel/resolver-default": "^2.12.0",
    "@sentry/cli": "^2.29.1",
    "@testing-library/jest-dom": "^6.4.2",
    "@testing-library/react": "^14.2.1",
    "@types/jest": "^29.5.12",
    "@types/react": "^18.2.61",
    "@types/react-dom": "^18.2.19",
    "@typescript-eslint/eslint-plugin": "^7.1.0",
    "@typescript-eslint/parser": "^7.1.0",
    "buffer": "^6.0.3",
    "cypress": "^13.6.6",
    "cypress-cucumber-steps": "^3.59.0",
    "cz-ls-commits": "^1.1.0",
    "eslint": "^8.57.0",
    "eslint-import-resolver-babel-module": "^5.3.2",
    "eslint-plugin-jest": "^27.9.0",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-react": "^7.33.2",
    "eslint-plugin-simple-import-sort": "^12.0.0",
    "eslint-plugin-tsdoc": "^0.2.17",
    "gherkin-lint": "^4.2.4",
    "husky": "^9.0.11",
    "jest": "^29.6.2",
    "jest-canvas-mock": "^2.5.2",
    "jest-environment-jsdom": "^29.6.2",
    "jest-fetch-mock": "^3.0.3",
    "lint-staged": "^15.2.2",
    "parcel": "^2.12.0",
    "prettier": "^3.2.5",
    "process": "^0.11.10",
    "ts-jest": "^29.1.2",
    "ts-node": "^10.9.2",
    "typescript": "^5.3.3"
  },
  "engines": {
    "node": "18",
    "yarn": "1"
  }
}
