import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Container, LinearProgress, Link, Modal, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import UploadFile from '../../File/Uploader';
import { KEY_IMPORTED, KEY_SPLIT_COMPLETED, MAX_FILE_SIZE_IN_MB } from '../../Types/Import';
import { PropsUploadFileModal } from './types';

export const uploadFile = async (files: File[] | null) => {
  if (!files || !files.length) {
    return null;
  }

  return UploadFile({ file: files[0] });
};

export default function UploadFileModal(props: PropsUploadFileModal) {
  const { uploadedImport, shouldBlockUploads, shouldRenderModal, onModalClose, onSelectedFile } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onSelectedFile,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE_IN_MB,
    accept: {
      'text/csv': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel': [],
    },
  });

  const renderImportProgress = () => {
    const { filename } = props;
    const isImportOver = uploadedImport?.status === KEY_IMPORTED;
    const isSplitCompleted = uploadedImport?.status === KEY_SPLIT_COMPLETED;
    const progressVariant = isSplitCompleted || isImportOver ? 'determinate' : 'indeterminate';
    const statusLabel = isImportOver ? 'Complete' : 'Loading';
    const isImportUnavailable = !uploadedImport;
    const progressBarValue = isImportUnavailable
      ? 0
      : (uploadedImport.processedLines / uploadedImport.totalLines) * 100;

    const fileSize = isImportUnavailable ? 0 : Math.trunc(uploadedImport.fileSize / 1000);
    const fileSizeLabel = fileSize === 0 ? 'Calculating size' : `${fileSize}kb`;
    return (
      <Container sx={{ textAlign: 'left' }} style={{ minWidth: '35vw', maxWidth: '90vw' }}>
        <Typography variant="body1" gutterBottom>
          {filename}
        </Typography>
        <Typography paragraph title="Import Status" color="text.secondary">
          {fileSizeLabel} - {statusLabel}
        </Typography>
        <LinearProgress sx={{ marginTop: '1rem' }} variant={progressVariant} value={progressBarValue} />
      </Container>
    );
  };

  const renderModalContent = () => {
    if (shouldBlockUploads) {
      return renderImportProgress();
    }

    return (
      <section>
        <Box style={{ display: 'inline-block', width: '100%', textAlign: 'right' }}>
          <CloseIcon
            onClick={onModalClose}
            data-testid="modal-close-icon"
            style={{
              display: 'block',
              float: 'right',
              marginTop: '-2rem',
              marginRight: '-1.5rem',
              width: '3.1rem',
              height: '1.5rem',
            }}
          />
        </Box>
        <div {...getRootProps()} data-testid="dropzone">
          <input {...getInputProps()} />
          <Box>
            <UploadFileIcon
              color="info"
              sx={{
                borderRadius: '50%',
                backgroundColor: 'colors.blue.light',
                width: '3.1rem',
                height: '3.1rem',
                padding: '.5rem',
              }}
            />
          </Box>
          <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
            <Link variant="body1" style={{ cursor: 'pointer' }}>
              Click to upload
            </Link>
            <Typography variant="body1" style={{ display: 'inline', cursor: 'pointer' }} gutterBottom>
              {` or drag and drop`}
            </Typography>
          </div>
          <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="body1" gutterBottom>
            CSV (max. 3MB)
          </Typography>
        </div>
      </section>
    );
  };

  return (
    <Modal
      open={shouldRenderModal}
      onClose={onModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          textAlign: 'center',
          p: 4,
          minWidth: '35vw',
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        {renderModalContent()}
      </Box>
    </Modal>
  );
}
