import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { HTTP_NOT_FOUND, HTTP_UNAUTHORIZED } from '../Types/Http';

export default function ErrorPage() {
  const { code = '0' } = useParams<{ code: string }>();
  const parsedCode = parseInt(code, 10);
  const codeValue = Number.isNaN(parsedCode) ? 0 : parsedCode;

  const navigate = useNavigate();

  let message: string;
  let description: string;
  switch (codeValue) {
    case HTTP_NOT_FOUND:
      message = 'Page not found.';
      description = 'Looks like this page has either been removed, or has never even existed.';
      break;
    case HTTP_UNAUTHORIZED:
      message = 'You are not authorized to access this page.';
      description = '';
      break;
    default:
      message = 'Oops! Something went wrong.';
      description = 'We apologize for the inconvenience. Please try again later.';
      break;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'lsBackground.main',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          backgroundColor: 'lsBackground.light',
          borderRadius: '0.25rem',
          padding: '1.875rem',
          fontFamily: 'sans-serif',
          borderTop: '0.375rem solid',
          borderColor: 'lsForeground.main',
          width: '100%',
          maxWidth: '768px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Typography component="h1" variant="h4" sx={{ marginRight: 3 }}>
            <strong>{codeValue}</strong>
          </Typography>

          <Typography component="h2" variant="overline">
            {message}
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Typography paragraph>{description}</Typography>

          <Button onClick={() => navigate(-1)} variant="contained" sx={{ marginRight: 2 }} color="lsForeground">
            Retry
          </Button>

          <Button component={Link} to="/" variant="outlined" color="lsForeground">
            Go to home
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
