import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Sanctum } from 'react-sanctum';

import ActiveCustomersDashboard from './app/ActiveCustomersDashboard';
import ErrorPage from './app/ErrorPage';
import ImportErrors from './app/ImportErrors';
import Login from './app/Login';
import { theme } from './theme';

export const sanctumConfig = {
  apiUrl: String(process.env.REACT_PARTNERS_API_BASE_URL),
  csrfCookieRoute: 'sanctum/csrf-cookie',
  signInRoute: 'api/auth/login',
  signOutRoute: 'api/auth/logout',
  userObjectRoute: 'api/user',
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Sanctum config={sanctumConfig} checkOnInit={false}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/active-customers" element={<ActiveCustomersDashboard />} />
            <Route path="/imports/errors/:id/:type?" element={<ImportErrors />} />
            <Route path="/error/:code?" element={<ErrorPage />} />
            <Route path="*" element={<Navigate replace to="/error/404" />} />
          </Routes>
        </BrowserRouter>
      </Sanctum>
    </ThemeProvider>
  );
}
