import { gql } from '@apollo/client';

export const CreateExportMutation = gql`
  mutation ($filters: [ScheduleEventFilter!]!) {
    createExport(input: { filters: $filters }) {
      export {
        id
        status
        fileName
      }
    }
  }
`;
