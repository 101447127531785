import { Column } from '@material-table/core';
import { MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import { MTableColumnFilter } from '../Types/MaterialTable';
import { SERVICE_TYPE_MAP } from '../Types/Schedule';
import {
  EXTERNAL_STATUS_COLOR,
  EXTERNAL_STATUS_MAP,
  ScheduleEvent,
  ScheduleEventCSVExport,
} from '../Types/ScheduleEvent';

// The percent modifier to be used when displaying/exporting lawn sizes
const LAWN_SIZE_MODIFIER = 0.65;

export const formatPayout = (payout: number): string => {
  /** payout is returned in cents from API */
  const targetPayout = payout / 100;
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(targetPayout);
};

export const renderPayout = (row: ScheduleEvent): string => {
  const { servicePayout } = row;
  return !servicePayout ? '' : formatPayout(servicePayout.payout);
};

export const getServiceType = (row: ScheduleEvent): string => {
  return SERVICE_TYPE_MAP[row.schedule.service.type] || '';
};

export const exportTransformerPayout = (row: ScheduleEventCSVExport): string => {
  const payout = row['servicePayout.payout'];
  if (!payout) {
    return '';
  }

  const targetPayout = formatPayout(payout);
  return targetPayout.replace('$', '');
};

export const exportTransformerLawnSize = (row: ScheduleEventCSVExport): string => {
  const yardSizeInSqft = row['schedule.property.yardSizeInSqft'];
  return yardSizeInSqft ? Math.round(yardSizeInSqft * LAWN_SIZE_MODIFIER).toString() : '';
};

export const exportTransformerAddress = (row: ScheduleEventCSVExport): string =>
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  `${row['schedule.property.address1']}, ${row['schedule.property.city']}, ${row['schedule.property.state']} ${row['schedule.property.postalCode']}`;

export const formatDate = (dateString: string): string => new Date(dateString).toISOString().split('T')[0];

export const dateColumn = (exportedTitle: string, title: string, key: keyof ScheduleEvent) => {
  return {
    title: <Box title={exportedTitle}>{title}</Box>,
    field: key,
    width: '7rem',
    render: (row: ScheduleEvent) => formatDateRow(row, key),
    exportTransformer: (row: ScheduleEvent) => formatDateRow(row, key),
    filtering: false,
  } as Column<ScheduleEvent>;
};

export const getFilterValue = (input: MTableColumnFilter) => input.columnDef.tableData?.filterValue ?? '';

export const truncatedColumn = (exportedTitle: string, title: string, filterPlaceholder: string, key: string) => {
  return {
    title: <Box title={exportedTitle}>{title}</Box>,
    field: key,
    filtering: true,
    filterComponent: (props: MTableColumnFilter) => {
      return (
        <TextField
          inputProps={{ 'data-testid': `filter-${key}` }}
          placeholder={filterPlaceholder}
          label={title}
          onChange={onChangeFilter(props)}
          value={getFilterValue(props)}
        />
      );
    },
  } as Column<ScheduleEvent>;
};

export const createTooltip = (value: string) => (
  <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{value}</span>} placement="top" arrow>
    <Box
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {value.split('\n')[0]}
    </Box>
  </Tooltip>
);

export const renderStatus = (row: ScheduleEvent) => (
  <Chip label={EXTERNAL_STATUS_MAP[row.status]} color={EXTERNAL_STATUS_COLOR[row.status]} />
);

const formatDateRow = (row: ScheduleEvent, key: keyof ScheduleEvent) =>
  row[key] ? formatDate(row[key] as string) : '';

export const onChangeFilter = (props: MTableColumnFilter) => (event: React.ChangeEvent<HTMLInputElement>) => {
  props.columnDef.tableData && props.onFilterChanged(props.columnDef.tableData.id, event.target.value);
};

export const TableConfiguration: Column<ScheduleEvent>[] = [
  {
    ...truncatedColumn('Schedule Event ID', 'ID', 'Filter Event ID', 'id'),
    render: (row: ScheduleEvent) => createTooltip(row.id),
  },
  {
    ...truncatedColumn('Name', 'Name', 'Filter Name', 'customerName'),
    render: (row: ScheduleEvent) => createTooltip(row.schedule.property.customerName),
  },
  { hidden: true, field: 'schedule.property.city' },
  { hidden: true, field: 'schedule.property.state' },
  { hidden: true, field: 'schedule.property.postalCode' },
  {
    ...truncatedColumn('Address', 'Address', 'Filter Address', 'address'),
    render: (row: ScheduleEvent) =>
      createTooltip(
        `${row.schedule.property.address1}
      ${row.schedule.property.city}, ${row.schedule.property.state} ${row.schedule.property.postalCode}
      `,
      ),
    exportTransformer: exportTransformerAddress,
  },
  {
    title: <Box title="Lawn Size">Lawn Size</Box>,
    field: 'schedule.property.yardSizeInSqft',
    filtering: false,
    hidden: true,
    export: true,
    exportTransformer: exportTransformerLawnSize,
  },
  {
    title: <Box title="Property ID">Property</Box>,
    field: 'schedule.property.id',
    filtering: false,
    hidden: true,
    export: true,
  },
  {
    title: <Box title="Service">Service</Box>,
    filtering: false,
    field: 'service',
    render: getServiceType,
    width: '7rem',
  },
  {
    title: <Box title="Payout">Payout</Box>,
    field: 'payout',
    render: renderPayout,
    filtering: false,
    width: '6rem',
    exportTransformer: exportTransformerPayout,
  },
  dateColumn('Service Window Start', 'Starts', 'windowStart'),
  dateColumn('Service Window End', 'Ends', 'windowEnd'),
  dateColumn('Schedule Date', 'Scheduled', 'startedAt'),
  dateColumn('Complete Date', 'Completed', 'completedAt'),
  {
    title: <Box title="Status">Status</Box>,
    field: 'status',
    lookup: EXTERNAL_STATUS_MAP,
    filterComponent: (props: MTableColumnFilter) => (
      <TextField
        data-testid="filter-status"
        select
        sx={{ minWidth: '10rem' }}
        label="Status"
        defaultValue=""
        value={getFilterValue(props)}
        onChange={onChangeFilter(props)}
      >
        <MenuItem value="">All Statuses</MenuItem>
        {props.columnDef.lookup &&
          Object.keys(props.columnDef.lookup).map((key: string) => (
            <MenuItem key={key} value={EXTERNAL_STATUS_MAP[key]} data-testid={`filter-value-${key}`}>
              {props.columnDef.lookup && (props.columnDef.lookup as typeof EXTERNAL_STATUS_MAP)[key]}
            </MenuItem>
          ))}
      </TextField>
    ),
    render: renderStatus,
    width: '13rem',
    align: 'left',
  },
  {
    title: <Box title="Technician">Technician</Box>,
    field: 'technician.name',
    hidden: true,
    export: true,
  },
  { title: <Box title="Products Applied">Products Applied</Box>, field: 'productsUsed', hidden: true, export: true },
  { title: <Box title="Specialist Notes">Notes</Box>, field: 'notes', hidden: true, export: true },
];
