export const KEY_NEW = 'NEW';
export const KEY_UPLOADED = 'UPLOADED';
export const KEY_SPLIT_STARTED = 'SPLIT_STARTED';
export const KEY_SPLIT_COMPLETED = 'SPLIT_COMPLETED';
export const KEY_IMPORTED = 'IMPORTED';
export const KEY_ERROR = 'ERROR';
export const KEY_FAILURE = 'FAILURE';

export const PROGRESS_INITIALIZING = 'The processing is being initialized';
export const PROGRESS_PREPARING = 'Preparing the records to be processed';
export const PROGRESS_IMPORTING = 'Records are being processed';
export const PROGRESS_IMPORTED =
  "All records have been processed sucessfully, you'll be redirected to the dashboard...";
export const PROGRESS_ERROR = "Errors have been found, you'll be redirected to a report...";

export const IMPORT_STATUS_PROGRESS_MAP: Record<string, string> = {
  [KEY_NEW]: PROGRESS_INITIALIZING,
  [KEY_UPLOADED]: PROGRESS_INITIALIZING,
  [KEY_SPLIT_STARTED]: PROGRESS_PREPARING,
  [KEY_SPLIT_COMPLETED]: PROGRESS_IMPORTING,
  [KEY_IMPORTED]: PROGRESS_IMPORTED,
  [KEY_ERROR]: PROGRESS_ERROR,
  [KEY_FAILURE]: PROGRESS_ERROR,
} as const;

export type Import = {
  id: string;
  status: string;
  fileName: string;
  fileSize: number;
  totalLines: number;
  processedLines: number;
  erroredLines: number;
};

export type ImportQueryResult = {
  data: {
    import: Import;
  };
};

export const ONE_MB = 1048576;
export const MAX_FILE_SIZE_IN_MB = 3 * ONE_MB;
