import { Property } from './Property';
import { Service } from './Service';

export type Schedule = {
  service: Service;
  property: Property;
};

export enum ServiceType {
  Aeration = 'AERATION',
  AerationOverseeding = 'AOS',
  Chinch = 'CHINCH',
  DiseaseFungicide = 'DISEASE_FUNGICIDE',
  Fertilization = 'FERTILIZATION',
  Fireant = 'FIREANT',
  Grub = 'GRUB',
  LawnTreatment = 'LAWN_TREATMENT',
  Lime = 'LIME',
  Mosquito = 'MOSQUITO',
}

export const SERVICE_TYPE_MAP = {
  [ServiceType.Aeration]: 'Aeration',
  [ServiceType.AerationOverseeding]: 'AOS',
  [ServiceType.Chinch]: 'Chinch',
  [ServiceType.DiseaseFungicide]: 'Disease',
  [ServiceType.Fertilization]: 'Treatment',
  [ServiceType.Fireant]: 'Fireant',
  [ServiceType.Grub]: 'Grub',
  [ServiceType.LawnTreatment]: 'Treatment',
  [ServiceType.Lime]: 'Lime',
  [ServiceType.Mosquito]: 'Mosquito',
};
