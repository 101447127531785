import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    lsBackground: {
      main: '#eff1f0',
      light: '#f8f9fa',
      dark: '#d8d8d8',
    },
    lsForeground: {
      main: '#067042',
      light: '#e6f1ec',
      dark: '#055a35',
      contrastText: '#FFF',
    },
    colors: {
      blue: {
        main: 'UNIMPLEMENTED',
        light: '#E3EEF9',
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width: 0px)': {
            padding: '0',
            minWidth: '1280px',
          },
        },
      },
    },
  },
});
