import { AppBar, Box, Container, Toolbar } from '@mui/material';

import lsLogo from '../../assets/logo.svg';
import UserMenu from './UserMenu';

export default function TopBar() {
  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="xl" style={{ padding: 0 }}>
        <Toolbar>
          <Box component="img" src={String(lsLogo)} sx={{ height: '1.1rem' }} />
          <Box sx={{ flexGrow: 1 }} />
          <UserMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
