import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { FlagCollection, FlagsResult } from '../Types/Flags';

export const lsApi = createApi({
  reducerPath: 'lsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: String(process.env.REACT_LS_API_BASE_URL),
  }),
  endpoints: (builder) => ({
    getFlags: builder.query<FlagCollection, void>({
      query: () => '/v1/flags',
      transformResponse: (response: FlagsResult) => {
        return response.data;
      },
    }),
  }),
});

export const { endpoints, useGetFlagsQuery } = lsApi;
