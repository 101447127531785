import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import fetch from 'cross-fetch';

import { getGraphQLClient } from '../../GraphQL';
import { CreateImportMutation } from '../../GraphQL/CreateImportMutation';
import { FinalizeImportMutation } from '../../GraphQL/FinalizeImportMutation';
import { CreateImport } from '../../Types/CreateImport';
import { CreateImportResult } from '../../Types/CreateImportResult';
import { isSuccessfulResponse } from '../../Types/Http';

export interface UploaderProps {
  file: File;
  graphQLClient?: ApolloClient<NormalizedCacheObject>;
  maxFileSize?: number;
}

export default async function UploadFile(props: UploaderProps): Promise<string | null> {
  const maxFileSize = props.maxFileSize || 100 * 1024 * 1024;
  const { file } = props;
  const { size } = file;

  if (size > maxFileSize) {
    alert(`Maximum file size is ${maxFileSize / 1024 / 1024}Mb`);
    return null;
  }

  try {
    const createImportResult = await createImport(props);
    /* istanbul ignore next */
    return createImportResult?.fileName || null;
  } catch (e) {
    return null;
  }
}

const createImport = async (props: UploaderProps): Promise<CreateImport | null> => {
  const uploadHeaders = {
    'X-Amz-Content-Sha256': 'UNSIGNED-PAYLOAD',
  };

  const { graphQLClient, file } = props;
  const graphqlClient = graphQLClient || getGraphQLClient();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data } = await graphqlClient.mutate({ mutation: CreateImportMutation });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { createImport: createImportResult }: CreateImportResult = data;
  const { presignedUploadUrl } = createImportResult;
  const fileObj = new File([file], createImportResult.fileName);
  const uploadResponse = await fetch(presignedUploadUrl, {
    method: 'PUT',
    body: fileObj,
    headers: uploadHeaders,
  });

  /* istanbul ignore next */
  if (!isSuccessfulResponse(uploadResponse.status)) {
    return null;
  }

  await graphqlClient.mutate({
    mutation: FinalizeImportMutation,
    variables: { id: createImportResult.id },
  });

  return createImportResult;
};
