import { CircularProgress, Grid, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';

import { SanctumReturn, User } from '../Types/Auth';

export default function Login() {
  const { authenticated, checkAuthentication, signIn }: SanctumReturn<User> = useSanctum();
  const [isLoading, setIsLoading] = useState(true);
  const [isDirtyPassword, setIsDirtyPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [shouldRenderButton, setShouldRenderButton] = useState(true);

  const navigate = useNavigate();
  const dashboardUrl = '/active-customers';

  useEffect(() => {
    /** Not using finally function here to not cause flickering screen */
    checkAuthentication().then(handleUserSession).catch(handleOutdatedSession);
  });

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShouldRenderButton(false);
    const loginForm = e.currentTarget;
    const formElements = loginForm.elements as typeof loginForm.elements & {
      loginEmail: { value: string };
      loginPassword: { value: string };
    };

    signIn(formElements.loginEmail.value, formElements.loginPassword.value, true)
      .then(() => navigate(dashboardUrl))
      .catch(() => alert('Incorrect email or password'))
      .finally(() => setShouldRenderButton(true));
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value: currentPassword },
    } = e;

    const minimumPasswordLength = 8;
    const shouldAcceptPassword = currentPassword.length >= minimumPasswordLength;
    setIsValidPassword(shouldAcceptPassword);
    setIsDirtyPassword(true);
  };

  const handleUserSession = () => {
    if (authenticated) {
      navigate(dashboardUrl);
    }

    setIsLoading(false);
  };

  const handleOutdatedSession = () => {
    setIsLoading(false);
  };

  const renderLoadingSpinner = (sxOverride: SxProps<Theme> = {}) => {
    return (
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          ...sxOverride,
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const renderButton = () => {
    if (!shouldRenderButton) {
      const sx = { marginTop: 4 };
      return renderLoadingSpinner(sx);
    }

    const isButtonDisabled = !isDirtyPassword || !isValidPassword;
    return (
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isButtonDisabled}>
        Sign In
      </Button>
    );
  };

  const renderForm = () => {
    if (isLoading) {
      return renderLoadingSpinner();
    }

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Box
          sx={{
            verticalAlign: 'middle',
            display: 'flex',
            flexDirection: 'column',
            minWidth: 540,
          }}
        >
          <Typography component="h1" variant="h5" style={{ marginBottom: '1rem' }}>
            Sign in to Platform
          </Typography>
          <form onSubmit={onSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="loginEmail"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
              inputProps={{ 'aria-label': 'email' }}
              type="email"
            />
            <TextField
              error={isDirtyPassword && !isValidPassword}
              margin="normal"
              required
              fullWidth
              id="loginPassword"
              label="Password"
              type="password"
              name="password"
              autoComplete="current-password"
              inputProps={{ 'aria-label': 'password' }}
              onChange={(e) => onChangePassword(e)}
            />
            {renderButton()}
          </form>
        </Box>
      </Grid>
    );
  };

  return renderForm();
}
