import { gql } from '@apollo/client';

export const CreateImportMutation = gql`
  mutation {
    createImport(importType: 1) {
      id
      tenant {
        id
      }
      importType
      status
      fileName
      fileSize
      fileHash
      presignedUploadUrl
      createdAt
      updatedAt
    }
  }
`;
