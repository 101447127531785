import { gql } from '@apollo/client';

export const FinalizeImportMutation = gql`
  mutation ($id: HashID!) {
    finalizeImport(id: $id) {
      id
      status
      fileSize
    }
  }
`;
