import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import MaterialTable, { Filter, MTableFilterRow, OrderByCollection, Query, QueryResult } from '@material-table/core';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';

import TopBar from '../components/TopBar';
import { CLOSE_ALERT_AUTOMATICALLY_IN_MS, REFRESH_RATIO_IN_MS, WAIT_FOR_REDIRECT_IN_MS } from '../constants';
import { getGraphQLClient } from '../GraphQL';
import { CreateExportMutation } from '../GraphQL/CreateExportMutation';
import { GetExportQuery } from '../GraphQL/GetExportQuery';
import { ImportQuery } from '../ImportProgress/ImportQuery';
import { SanctumReturn, User } from '../Types/Auth';
import {
  CreateExportResponse,
  Export,
  EXPORT_STATUS_COMPLETED,
  EXPORT_STATUS_FAILURE,
  GetExportResponse,
} from '../Types/Export';
import { Import, ImportQueryResult, KEY_ERROR, KEY_FAILURE, KEY_IMPORTED } from '../Types/Import';
import {
  FILTER_COLUMN_OPERATOR,
  GetScheduleEventsResponse,
  ScheduleEvent,
  ScheduleEventsFilterInput,
  ScheduleEventsSortInput,
} from '../Types/ScheduleEvent';
import UploadFileModal, { uploadFile } from './components/UploadFileModal';
import { TableConfiguration } from './TableConfiguration';
import { TableQuery } from './TableQuery';

export interface DashboardProps {
  graphQLClient?: ApolloClient<NormalizedCacheObject>;
}

const remoteData = (
  filterBy: ScheduleEventsFilterInput[],
  sortBy: ScheduleEventsSortInput[],
  page: number,
  first: number,
): Promise<GetScheduleEventsResponse> =>
  getGraphQLClient().query({
    query: TableQuery,
    variables: { filterBy, sortBy, page, first },
  }) as Promise<GetScheduleEventsResponse>;

const pingImportStatus = (importId: string | undefined) =>
  getGraphQLClient().query({
    query: ImportQuery,
    variables: { id: importId },
  });

export default function ActiveCustomersDashboard() {
  const { authenticated }: SanctumReturn<User> = useSanctum();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [uploadedImport, setUploadedImport] = useState<Import | undefined>(undefined);
  const [showingSpinner, setShowingSpinner] = useState<boolean | string>(false);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | null>(null);
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const [shouldBlockUploads, setShouldBlockUploads] = useState(false);
  const [filename, setFilename] = useState<string | null>(null);
  const navigate = useNavigate();
  const onSelectedFile = useCallback((importFiles: File[] | null) => {
    setIsModalLoading(true);
    setShouldBlockUploads(true);
    void uploadFile(importFiles).then((targetFilename) => {
      if (!targetFilename) {
        return;
      }

      setFilename(targetFilename);
    });
  }, []);

  let currentFilters: Filter<ScheduleEvent>[] = [];

  useEffect(() => {
    if (authenticated === false) {
      navigate('/');
      return;
    }

    if (!isModalLoading || !filename) {
      return;
    }

    const importId = filename.substring('import_'.length, filename.indexOf('.'));
    pingImportStatus(importId)
      .then((result: ImportQueryResult) => {
        setIsModalLoading(false);
        setUploadedImport(result.data.import);
        const importStatus = result.data.import.status;
        if (importStatus === KEY_ERROR && result.data.import.processedLines === result.data.import.totalLines) {
          setTimeout(() => {
            navigate(`/imports/errors/${importId}`);
            resetModal();
          }, WAIT_FOR_REDIRECT_IN_MS);
          return;
        }

        if (importStatus === KEY_IMPORTED) {
          setTimeout(() => {
            resetModal();
            setAlertMessage(<Alert severity="success">Successfully Imported</Alert>);
            setTimeout(() => setAlertMessage(null), CLOSE_ALERT_AUTOMATICALLY_IN_MS);
          }, CLOSE_ALERT_AUTOMATICALLY_IN_MS);
          return;
        }

        if (importStatus === KEY_FAILURE) {
          setTimeout(() => {
            resetModal();
            navigate(`/imports/errors/${importId}/failure`);
          }, WAIT_FOR_REDIRECT_IN_MS);
          return;
        }

        setTimeout(() => setIsModalLoading(true), REFRESH_RATIO_IN_MS);
      })
      /**
       * TODO
       * https://lawnstarter.atlassian.net/browse/PE-24804
       */
      .catch(() => ({}));
  });

  const resetModal = () => {
    setShouldRenderModal(false);
    setShouldBlockUploads(false);
    setFilename(null);
    setUploadedImport(undefined);
  };

  const getFilterInputArray = (filters: Filter<ScheduleEvent>[]): ScheduleEventsFilterInput[] => {
    return filters.map((filter) => {
      const column = filter.column.field as string;
      const preValue = filter.value as string;
      const operator = FILTER_COLUMN_OPERATOR[column];
      const value = operator === 'LIKE' ? `%${preValue}%` : preValue;
      return {
        column,
        operator,
        value,
      };
    });
  };

  const getSortInputArray = (orderByCollection: OrderByCollection[]): ScheduleEventsSortInput[] => {
    return orderByCollection
      .filter((item) => !!item.sortOrder)
      .map((item) => {
        return {
          column: TableConfiguration[item.orderBy].field as string,
          order: item.orderDirection.toUpperCase(),
        };
      });
  };

  const fetchScheduleEvents = async (query: Query<ScheduleEvent>): Promise<QueryResult<ScheduleEvent>> => {
    const filterBy = getFilterInputArray(query.filters);
    const sortBy = getSortInputArray(query.orderByCollection);

    const {
      data: {
        GetScheduleEvents: {
          data: scheduleEvents,
          paginatorInfo: { total },
        },
      },
    } = await remoteData(filterBy, sortBy, query.page + 1, query.pageSize);
    return {
      data: scheduleEvents,
      page: query.page,
      totalCount: total,
    };
  };

  const displayExportError = (isExportDone?: number) => {
    setShowingSpinner(false);
    isExportDone && clearInterval(isExportDone);
    setAlertMessage(
      <Alert
        action={
          <IconButton
            aria-label="Close Alert"
            color="inherit"
            size="small"
            onClick={() => {
              setAlertMessage(null);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity="error"
      >
        <AlertTitle>Data Export Failed</AlertTitle>
        An unknown error occurred.
      </Alert>,
    );
  };

  const checkExportProgress = (exportFile: Export, isExportDone: number) => {
    return (async () => {
      const getExportResponse = await (getGraphQLClient().query<GetExportResponse>({
        query: GetExportQuery,
        variables: { id: exportFile.id },
      }) as Promise<GetExportResponse>);
      const exportProgress = getExportResponse.data.export;
      const fileName = exportProgress.fileName;
      const publicUrl = exportProgress.publicUrl;

      if (publicUrl && exportProgress.status === EXPORT_STATUS_COMPLETED) {
        setShowingSpinner(false);
        clearInterval(isExportDone);
        window.location.assign(publicUrl);
        const downloadLink = (
          <Link download={fileName} href={publicUrl}>
            click here
          </Link>
        );
        setAlertMessage(
          <Alert
            action={
              <IconButton
                aria-label="Close Alert"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertMessage(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Successfully Exported</AlertTitle>
            Your export is ready, {downloadLink} if the download hasn't started.
          </Alert>,
        );
      } else if (exportProgress.status !== EXPORT_STATUS_FAILURE) {
        return;
      } else {
        displayExportError(isExportDone);
      }
      setTimeout(() => setAlertMessage(null), CLOSE_ALERT_AUTOMATICALLY_IN_MS);
    })();
  };

  const exportData = async () => {
    setShowingSpinner('Your report is being generated. Please stay on the page.');
    const exportFilters = currentFilters.map((col: Filter<ScheduleEvent>) => {
      const columnName = col.column.field as string;
      const queryOperator = FILTER_COLUMN_OPERATOR[columnName];
      const columnValue = col.value as string;
      const queryValue = queryOperator === 'LIKE' ? `%${columnValue}%` : columnValue;
      return {
        column: columnName,
        operator: queryOperator,
        value: queryValue,
      };
    });
    try {
      const mutationResponse = await (getGraphQLClient().mutate<CreateExportResponse>({
        mutation: CreateExportMutation,
        variables: { filters: exportFilters },
      }) as Promise<CreateExportResponse>);
      const isExportDone = window.setInterval(() => {
        void checkExportProgress(mutationResponse.data.createExport.export, isExportDone);
      }, REFRESH_RATIO_IN_MS);
    } catch (err) {
      displayExportError();
    }
  };

  const handleModalClose = () => {
    setShouldRenderModal(false);
  };

  const renderModal = () => {
    if (!shouldRenderModal) {
      return null;
    }

    return (
      <UploadFileModal
        onModalClose={handleModalClose}
        onSelectedFile={onSelectedFile}
        shouldRenderModal={shouldRenderModal}
        shouldBlockUploads={shouldBlockUploads}
        filename={filename}
        uploadedImport={uploadedImport}
      />
    );
  };

  const renderTable = () => {
    if (!authenticated || showingSpinner) {
      return (
        <Container maxWidth="sm" sx={{ marginTop: 8, textAlign: 'center' }}>
          <CircularProgress sx={{ margin: 4 }} />
          {typeof showingSpinner === 'string' && <p color="text.secondary">{showingSpinner}</p>}
        </Container>
      );
    }

    return (
      <MaterialTable
        title="Active Customers"
        columns={TableConfiguration}
        data={fetchScheduleEvents}
        onFilterChange={(filters) => {
          currentFilters = filters;
        }}
        components={{
          Container: (props) => (
            <Paper
              {...props}
              elevation={0}
              sx={{
                '& tr:empty': { display: 'none' },
                '& tbody tr:nth-of-type(even)': { backgroundColor: 'lsBackground.light' },
                '& tbody tr td': { padding: '6px 16px' },
                '& thead tr th ': { backgroundColor: 'lsBackground.dark' },
                '& .MuiBox-root table.MuiTable-root': {
                  marginTop: '6rem',
                  '& tr:not(:first-of-type) td.MuiTableCell-root:empty': {
                    '&::after': {
                      content: '"—"',
                    },
                    textAlign: 'center',
                  },
                },
                '& #m--table--filter--row': {
                  position: 'absolute',
                  zIndex: 1,
                  top: 0,
                  '& td': {
                    border: 0,
                    width: '13rem',
                    padding: '0.8rem',
                    '&:empty': {
                      width: 0,
                      padding: 0,
                    },
                    '&:first-of-type': {
                      paddingLeft: 0,
                    },
                  },
                },
              }}
            />
          ),
          FilterRow: (props) => {
            return <MTableFilterRow {...props} />;
          },
          Toolbar: () => (
            <Paper
              elevation={0}
              sx={{
                zIndex: 1,
                position: 'absolute',
                right: 0,
                top: '1.3rem',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                '& > *:not(:last-child)': {
                  marginRight: '0.5rem',
                },
              }}
            >
              <Button variant="contained" component="label" onClick={() => setShouldRenderModal(true)}>
                Import
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  void exportData();
                }}
              >
                Export
              </Button>
            </Paper>
          ),
        }}
        options={{
          paging: true,
          search: false,
          showTitle: false,
          filtering: true,
          hideFilterIcons: true,
          tableLayout: 'fixed',
          pageSize: 100,
          pageSizeOptions: [5, 10, 20, 50, 100],
          // https://github.com/material-table-core/core/issues/124
          rowStyle: { fontFamily: 'Roboto', fontSize: '0.875rem', overflowWrap: 'break-word' },
          headerStyle: {
            fontWeight: 'bold',
            position: 'sticky',
            top: '6rem',
          },
          overflowY: 'auto',
          maxBodyHeight: 'calc(100vh - 10.4rem)',
        }}
      />
    );
  };

  return (
    <Container maxWidth={false}>
      <TopBar />
      {renderModal()}
      <Container maxWidth="xl">
        <Container style={{ maxWidth: '98%' }}>{alertMessage}</Container>
        <Box
          sx={{
            backgroundColor: 'white',
            position: 'absolute',
            width: '100%',
            height: '6rem',
            zIndex: 1,
            marginTop: '3rem',
          }}
        ></Box>
        <Box sx={{ padding: '1em 1em 0 1em' }}>
          <Typography component="h1" variant="h6">
            Active Customers
          </Typography>
          {renderTable()}
        </Box>
      </Container>
    </Container>
  );
}
