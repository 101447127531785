export type Export = {
  id: string;
  status: string;
  fileName: string;
  publicUrl?: string;
};

export type CreateExportResponse = {
  data: {
    createExport: {
      export: Export;
    };
  };
};

export type GetExportResponse = {
  data: {
    export: Export;
  };
};

export const EXPORT_STATUS_COMPLETED = 'COMPLETED';
export const EXPORT_STATUS_FAILURE = 'FAILURE';
