import { gql } from '@apollo/client';

export const ImportRowQuery = gql`
  query ($id: HashID!) {
    import(id: $id) {
      id
      importRows {
        id
        lineNumber
        errors
        data
      }
    }
  }
`;
